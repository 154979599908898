@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: DOS, Monaco, Menlo, Consolas, "Courier New", monospace;
  font-weight: 400;
  line-height: 14px;
  font-size: 16px;
  line-height: 20px;
  color: #bbbbbb;
  text-align: left;
  background-color: #000084;
  -webkit-font-kerning: none;
  font-kerning: none;
  text-rendering: geometricPrecision;
}
.test__Shadow {
  filter: drop-shadow(7px 8px 0 black);
  margin-top: 23px;
  margin-bottom: 7px;
}
.test__Shadow:active {
  margin-top: 30px;
  margin-bottom: 0;
  translate: 10px;
  filter: none;
}
.post {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #000000;
  pointer-events: auto;
  background-color: #bbbbbb;
  background-clip: padding-box;
  outline: 0;
  margin: 0 190px;
  min-height: 100vh;
  margin-top: 10px;
}
.post h2:first-of-type {
  display: none;
}

.post h1 {
  background-color: transparent;
  font-size: 1.875rem !important;
  text-align: center;
  font-weight: bold;
  background-color: #00aaaa;
  padding: 15px;
}

.post p {
  margin: 14px 20px;
  line-height: 20px;
}
.post ol,
.post ul,
.post dl {
  margin-top: 0;
  margin-left: 8px;
  padding-left: 0;
  margin-bottom: 14px;
  list-style: disc;
}
.post li {
  list-style-type: disc !important;
  margin-bottom: 5px;
  font-weight: bold;
  margin-left: 40px;
  margin-right: 40px;

}
.post a {
  color: #000084;
  font-weight: bold;
}
.post h2 {
  text-align: center;
  margin: 10px 10px;
  padding: 10px;
  background-color: #aa00aa;
  font-size: 24px;
  color: #fff;
  font-size: bold;
}

.blink {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveRightLeft {
  0% {
    transform: translateX(0px);
  }
  24% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(25px);
  }
  49% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(50px);
  }
  74% {
    transform: translateX(50px);
  }
  75% {
    transform: translateX(25px);
  }
  99% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0px);
  }
}

.labs_text {
  overflow: hidden;
  animation: moveRightLeft 3s infinite;
}
@media only screen and (max-width: 1024px) {
  .post {
    margin: 0 20px;
  }
}
